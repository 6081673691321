import React from 'react';
import { useDialogState } from 'reakit/Dialog';
import { Flex, Box } from 'rebass';
import { FiDownloadCloud, FiArrowUpRight, FiFile } from 'react-icons/fi';
import Text from '../../../Text';
import { DialogBackdrop, Dialog, DialogDisclosure } from '../../../Dialog';
import styled from 'styled-components';
import BbshDocumentCustomDialog from '../CustomDialog';
import Card from '../../../Card';
import Container from '../../../Container';
import MembersEmptySlate from '../../EmptySlate';

const embeddableMimeTypes = [
  'application/pdf',
  'video/ogg',
  'video/mp4',
  'video/mp4',
  'video/webm',
  'video/webm',
  'video/webm',
  'audio/wav',
  'audio/mp3',
  'audio/mpeg',
  'audio/mp4',
  'audio/aac',
  'audio/aacp',
  'audio/ogg',
  'audio/webm',
  'audio/ogg',
  'audio/webm',
  'audio/flac',
  'audio/ogg',
];

const HtmlObject = styled.object`
  width: 100%;
  height: 100%;
  flex: 1;
`;

const FileCover = styled(Box)`
  border-radius: 4px;
  width: 128px;
  height: 184px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(
    123.47deg,
    rgba(102, 50, 111, 0.8) 0%,
    rgba(43, 25, 83, 0.8) 100%
  );
`;

const IconBg = styled(Box)`
  width: 72px;
  height: 72px;
  background: linear-gradient(134.52deg, #c5436d 0%, #dfa579 100%);
  transform: translate(50%, -50%) rotate(45deg);
  position: absolute;
  top: 0;
  right: 0;
`;

const IconWrapper = styled(Box).attrs({ p: 2 })`
  position: absolute;
  top: 0;
  right: 0%;
  color: white;
`;

const FileCard = ({ file, ...props }) => {
  const fileFormat = file?.url?.split('.').pop();
  const splitName = file?.name?.split('.');
  splitName.pop();
  const nameWithoutFormat = splitName;
  const objectType = embeddableMimeTypes.find(type =>
    type.includes(fileFormat),
  );
  return (
    <Card p={3} {...props}>
      <Flex px={3} mx={-3}>
        <FileCover px={3}>
          <IconBg />
          <IconWrapper>
            {objectType ? <FiArrowUpRight /> : <FiDownloadCloud />}
          </IconWrapper>
          <Text color="white">
            <FiFile size={24} />
            <br />
            {fileFormat.toUpperCase()}
          </Text>
        </FileCover>
        <Box px={3}>
          <Text fontSize={2} mb={1} textAlign="left" variant="secondary">
            {nameWithoutFormat}
          </Text>
          <Text
            textAlign="left"
            color="valhalla"
            fontWeight="500"
            variant="secondary"
          >
            {(file.size / 1000000).toFixed(2)} MB
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};

const FilesItem = ({ file }) => {
  const dialog = useDialogState({ modal: true });
  const fileFormat = file?.url?.split('.').pop();
  const splitName = file?.name?.split('.');
  splitName.pop();
  const nameWithoutFormat = splitName;
  const objectType = embeddableMimeTypes.find(type =>
    type.includes(fileFormat),
  );
  if (!objectType) {
    return (
      <a href={file?.url} target="_blank" rel="noreferrer">
        <FileCard file={file} />
      </a>
    );
  }
  return (
    <>
      <DialogDisclosure {...dialog} style={{ width: '100%' }}>
        <FileCard file={file} />
      </DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog background="white" {...dialog}>
          <BbshDocumentCustomDialog
            title={`Preview: ${nameWithoutFormat}`}
            hide={dialog.hide}
            url={file?.url}
          >
            <HtmlObject data={file.url} type={objectType}>
              <Container>
                <MembersEmptySlate
                  title="Oops."
                  illustration="ghost"
                  description={
                    <>
                      We couldn&apos;t load the document preview on this device.
                      Please download the file <a href={file.url}>here</a>
                    </>
                  }
                />
              </Container>
            </HtmlObject>
          </BbshDocumentCustomDialog>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default FilesItem;
