import React, { useEffect } from 'react';
import qs from 'qs';
import BbshDocumentPage from '../../../components/Members/BbshDocument/Page';
import usePrismic from '../../../utils/hooks/members/usePrismic';
import { getBbshDocument } from '../../../components/Members/queries';
import GateKeeper from '../../../components/FirebaseAuth/GateKeeper';
import SEO from '../../../components/seo';

const MembersDocumentPage = ({ location }) => {
  const { documentId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [fetch, { data, loading, error }] = usePrismic({
    query: getBbshDocument,
    variables: { id: documentId },
  });
  useEffect(() => {
    if (documentId) fetch();
  }, [documentId]);
  const bbshDocument = data?.allBbsh_documents?.edges?.[0]?.node;
  return (
    <GateKeeper>
      <SEO title={bbshDocument?.title?.[0]?.text} />
      <BbshDocumentPage
        isLoading={loading}
        error={error}
        bbshDocument={bbshDocument}
      />
    </GateKeeper>
  );
};

export default MembersDocumentPage;
