import {
  Dialog as ReakitDialog,
  DialogBackdrop as ReakitDialogBackdrop,
  DialogDisclosure as ReakitDialogDisclosure,
} from 'reakit/Dialog';
import styled from 'styled-components';
import { space, background, maxWidth, overflow } from 'styled-system';

export const Dialog = styled(ReakitDialog).attrs({
  my: 4,
})`
  background: white;
  max-width: 1024px;
  ${maxWidth}
  ${background}
  ${space}
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  ${overflow}
  position: fixed;
  width: 100%;
  /* bottom: 0; */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
`;

export const DialogBackdrop = styled(ReakitDialogBackdrop)`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const DialogDisclosure = styled(ReakitDialogDisclosure)`
  cursor: pointer;
`;
