import React from 'react';
import { Flex, Box } from 'rebass';
import FilesItem from './Item';

const BbshDocumentFiles = ({ files, ...props }) => {
  if (files?.length) {
    return (
      <Flex m={-3} flexWrap="wrap" {...props}>
        {files.map(({ file }) => {
          if (file) {
            return (
              <Box width={[1, 1 / 2, 1 / 3]} key={file.url} p={3}>
                <FilesItem file={file} />
              </Box>
            );
          }
        })}
      </Flex>
    );
  }
  return null;
};

export default BbshDocumentFiles;
