import React from 'react';
import { useDialogState } from 'reakit/Dialog';
import { Flex } from 'rebass';
import { FiPlay } from 'react-icons/fi';
import Text from '../../../Text';
import { DialogBackdrop, Dialog, DialogDisclosure } from '../../../Dialog';
import styled, { css } from 'styled-components';
import BbshDocumentCustomDialog from '../CustomDialog';
import Card from '../../../Card';

const iframeRegExp = new RegExp(/(width|height)="(\d+)"/);

const IframeWrapper = styled.div`
  position: relative;
  height: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    border: 0;
  }
`;

const Thumbnail = styled(Flex)`
  width: 100%;
  height: 192px;
  background: linear-gradient(
    155.86deg,
    rgba(102, 50, 111, 0.8) 0%,
    rgba(43, 25, 83, 0.8) 100%
  );
  ${({ src }) =>
    src &&
    css`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), url('${src}');
  background-blend-mode: multiply, normal;
  background-size: cover;
  background-position:center;
  `}
  align-items:center;
  justify-content: center;
`;

const PlayCircle = styled(Flex)`
  background: rgba(76, 75, 94, 0.4);
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const EmbedItem = ({ embed }) => {
  const dialog = useDialogState({ modal: true });
  /* remove width/height to use responsive iframe */
  const sanitizedHtml = embed?.html?.replace(iframeRegExp, '');
  return (
    <>
      <DialogDisclosure {...dialog} style={{ width: '100%' }}>
        <Card width={1} style={{ overflow: 'hidden' }}>
          <Thumbnail src={embed.thumbnail_url}>
            <PlayCircle>
              <FiPlay color="white" fill="white" size={24} />
            </PlayCircle>
          </Thumbnail>
          <Text p={3} textAlign="left" variant="secondary">
            {embed.title}
          </Text>
        </Card>
        {/* <Flex alignItems="center" target="_blank">
          <FiArrowUpRight size={16} />
          &nbsp;
        </Flex> */}
      </DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog background="white" {...dialog}>
          <BbshDocumentCustomDialog title={embed.title} hide={dialog.hide}>
            <IframeWrapper>
              <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
            </IframeWrapper>
          </BbshDocumentCustomDialog>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default EmbedItem;
