import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Text from '../../../Text';
import { FiX, FiDownload } from 'react-icons/fi';
import Button from '../../../Button';

const InnerDialog = styled(Flex)`
  height: calc(100vh - ${({ theme }) => theme.space[4] * 2}px);
  flex-direction: column;
  align-items: flex-start;
`;

const CloseButton = styled.button`
  cursor: pointer;
  display: inline-flex;
`;

const Heading = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
`;

const BbshDocumentCustomDialog = ({ title, children, url, hide }) => {
  return (
    <InnerDialog>
      <Heading width={1} p={3}>
        <Text mr={2}>{title}</Text>
        <Flex alignItems="center">
          {url && (
            <>
              <a href={url} target="_blank" rel="noreferrer">
                <Button
                  variant="outline"
                  px={2}
                  py={1}
                  css={{ display: 'flex', alignItems: 'center' }}
                >
                  Download&nbsp;
                  <FiDownload size={16} />
                </Button>
              </a>
              <Box mx={2} />
            </>
          )}
          <CloseButton onClick={hide}>
            <FiX size={24} />
          </CloseButton>
        </Flex>
      </Heading>
      <Content>{children}</Content>
    </InnerDialog>
  );
};

export default BbshDocumentCustomDialog;
