import React from 'react';
import EmbedItem from './Item';
import { Box, Flex } from 'rebass';

const BbshDocumentEmbeds = ({ embeds, ...props }) => {
  if (embeds?.length) {
    return (
      <Flex m={-3} flexWrap="wrap" {...props}>
        {embeds.map(({ embed }) => {
          if (embed) {
            return (
              <Box width={[1, 1 / 2, 1 / 3]} p={3} key={embed?.url}>
                <EmbedItem embed={embed} />
              </Box>
            );
          }
        })}
      </Flex>
    );
  }
  return null;
};

export default BbshDocumentEmbeds;
