import React from 'react';
import { RichText } from 'prismic-reactjs';
import MembersLayout from '../../Layout';
import BbshDocumentEmbeds from '../Embeds';
import Container from '../../../Container';
import BbshDocumentFiles from '../Files';
import Html from '../../../Layout/Html';
import styled from 'styled-components';
import Text from '../../../Text';
import { FiFileText, FiPlayCircle } from 'react-icons/fi';
import Hr from '../../../Layout/Hr';
import { Flex, Box } from 'rebass';
import { Location } from '@reach/router';
import qs from 'qs';
import Alert from '../../../Alert';
import MembersEmptySlate from '../../EmptySlate';

const ContentsText = styled(Text).attrs({ variant: 'secondary' })`
  display: flex;
  align-items: center;
`;

const BbshDocumentPage = ({ bbshDocument, isLoading, error, location }) => {
  const { goBack } = qs.parse(location.search, { ignoreQueryPrefix: true });
  return (
    <MembersLayout
      goBackLink={goBack || '/members'}
      // background="white"
      isLoading={isLoading}
      title={bbshDocument?.title?.[0]?.text || 'Document not found'}
    >
      <Container py={[3, 4]}>
        {error && (
          <Alert type="danger">
            {error?.message || error || 'An unexpected error occurred'}
          </Alert>
        )}
        {!isLoading && !bbshDocument && (
          <MembersEmptySlate
            title="Oops, this document was not found"
            description="Either it doesn't exist, or you don't have permission to view it."
          />
        )}
        {bbshDocument?.description && (
          <>
            <details title="About this document">
              <summary>About this document</summary>
              <Html>
                <RichText render={bbshDocument?.description} />
              </Html>
            </details>
            <Hr my={[3, 4, 5]} width={1} height={2} />
          </>
        )}
        <Flex m={-3} flexDirection="column">
          {!!bbshDocument?.files?.length && (
            <Box p={3}>
              <ContentsText mb={3}>
                <FiFileText />
                &nbsp;{bbshDocument?.files?.length} File
                {bbshDocument?.files?.length > 1 && 's'}
              </ContentsText>
              <BbshDocumentFiles files={bbshDocument?.files} />
            </Box>
          )}
          {!!bbshDocument?.files?.length && !!bbshDocument?.embeds?.length && (
            <Hr width={1} m={3} height={2} />
          )}
          {!!bbshDocument?.embeds?.length && (
            <Box p={3}>
              <ContentsText mb={3}>
                <FiPlayCircle />
                &nbsp;{bbshDocument?.embeds?.length} Media
              </ContentsText>
              <BbshDocumentEmbeds embeds={bbshDocument?.embeds} />
            </Box>
          )}
        </Flex>
      </Container>
    </MembersLayout>
  );
};

const BbshDocumentPageWithLocation = props => (
  <Location>
    {location => <BbshDocumentPage {...location} {...props} />}
  </Location>
);

BbshDocumentPageWithLocation.displayName = 'BbshDocumentPageWithLocation';

export default BbshDocumentPageWithLocation;
